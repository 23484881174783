<template>
  <div>
    <window-header></window-header>
    <nav-form
      :nuevo="false"
      :editar="false"
      :buscar="false"
      :eliminar="false"
      :cancelar="false"
    ></nav-form>
    <section>
      <article style="width: 300px">
        <r-tabs
          ref="main-tabs"
          style="top: 0px; height: 325px"
          :buttons="['Selección', 'Acotación']"
        >
          <div class="tab">
            <field
              ref="campos_seleccion_tabla"
              name="campos_seleccion_tabla"
              widget="handsontable"
              :height="275"
              :minRows="0"
              style="top: 10px; left: 4px; width: 280px"
              customprop="seleccion_customprop"
              :columns="[
                {
                  name: 'activo',
                  header: 'Activo',
                  widget: 'checkbox',
                  help: 'Activo',
                },
                { name: 'nombre', header: 'Campo', readOnly: true },
              ]"
            />
          </div>
          <div class="tab">
            <field
              ref="campos_acotacion_tabla"
              name="campos_acotacion_tabla"
              widget="handsontable"
              :height="275"
              :minRows="0"
              style="top: 10px; left: 4px; width: 280px"
              customprop="seleccion_customprop"
              :columns="[
                {
                  name: 'activo',
                  header: 'Activo',
                  widget: 'checkbox',
                  help: 'Activo',
                },
                { name: 'nombre', header: 'Campo', readOnly: true },
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin],
  props: {
    referencia: { type: String, required: true },
    model: { type: String, required: true },
    nombre: { type: String, required: true },
  },
  data: function () {
    var self = this;
    return {
      title: "Filtros",
      dbAdapter: "filtro_seleccion_acotacion",
      primary: "id",
      mode: "new",
      beforeSave: function (resolve, reject) {
        var self = this;
        let data = {
          id: self.formData.id,
          campos_seleccion: self.formData.campos_seleccion_tabla
            .filter((x) => parseInt(x.activo || 0))
            .map((x) => x.campo)
            .join(","),
          campos_acotacion: self.formData.campos_acotacion_tabla
            .filter((x) => parseInt(x.activo || 0))
            .map((x) => x.campo)
            .join(","),
        };
        //reject("asfs");
        resolve(data);
      },
    };
  },
  mounted() {
    var self = this;
    //self.loadItem(self.itemId);
    self.loading = true;
    window.DB.action("bd", "getCampos", { data: { model: self.model } })
      .then(
        ({ data: campos }) =>
          new Promise((resolve) => {
            window.DB.get("filtro_seleccion_acotacion", {
              itemId: self.referencia,
              fields: ["campos_seleccion", "campos_acotacion"],
            })
              .then((data) => {
                self.setMode("edit");
                self.itemId = self.referencia;
                resolve({ data, campos });
              })
              .catch((_) => resolve({ campos, data: {} }));
          })
      )
      .then(({ data, campos }) => {
        window.console.log(self.referencia, data);
        self.$set(self.formData, "id", self.referencia);
        self.$set(
          self.formData,
          "campos_seleccion_tabla",
          Object.values(campos).map((c) => ({
            campo: c.name,
            nombre: c.title || c.name,
            activo: (data.campos_seleccion?.split(",") || []).includes(c.name)
              ? 1
              : 0,
          }))
        );
        self.$set(
          self.formData,
          "campos_acotacion_tabla",
          Object.values(campos).map((c) => ({
            campo: c.name,
            nombre: c.title || c.name,
            activo: (data.campos_acotacion?.split(",") || []).includes(c.name)
              ? 1
              : 0,
          }))
        );
      }).finally(_ => self.loading = false);
  },
};
</script>