var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form',{attrs:{"nuevo":false,"editar":false,"buscar":false,"eliminar":false,"cancelar":false}}),_c('section',[_c('article',{staticStyle:{"width":"300px"}},[_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"0px","height":"325px"},attrs:{"buttons":['Selección', 'Acotación']}},[_c('div',{staticClass:"tab"},[_c('field',{ref:"campos_seleccion_tabla",staticStyle:{"top":"10px","left":"4px","width":"280px"},attrs:{"name":"campos_seleccion_tabla","widget":"handsontable","height":275,"minRows":0,"customprop":"seleccion_customprop","columns":[
              {
                name: 'activo',
                header: 'Activo',
                widget: 'checkbox',
                help: 'Activo',
              },
              { name: 'nombre', header: 'Campo', readOnly: true } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{ref:"campos_acotacion_tabla",staticStyle:{"top":"10px","left":"4px","width":"280px"},attrs:{"name":"campos_acotacion_tabla","widget":"handsontable","height":275,"minRows":0,"customprop":"seleccion_customprop","columns":[
              {
                name: 'activo',
                header: 'Activo',
                widget: 'checkbox',
                help: 'Activo',
              },
              { name: 'nombre', header: 'Campo', readOnly: true } ]}})],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }